import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router";
import { CompanyContext } from "../../contexts/companyContext";
import { getLatestCompletedSurvey } from "../../hooks/helpers";
import useApi from "../../hooks/useApi";
import { Survey, Respondent, Group, StrataJobTitle } from "../../types";
import Report from "../Report/Report";
import { AuthContext } from "../../contexts/authContext";

export default function OverviewResults() {
  const companyContext = useContext(CompanyContext);
  const { getApiData } = useApi();
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const surveyIdPath = parseInt(location.pathname.replace("/assessments/", ""));

  const cogntioUserId = auth.claims.sub;

  let surveyId: number | null = null;
  if (!isNaN(surveyIdPath)) {
    surveyId = surveyIdPath;
  }

  const { data: surveys } = useQuery<Survey[]>({
    queryKey: ["surveys", companyContext.companyId],
    queryFn: async () => getApiData(`/user/surveys`),
    initialData: [],
  });

  if (!surveyId) {
    const latestCompletedSurvey = getLatestCompletedSurvey(surveys);

    if (latestCompletedSurvey) {
      surveyId = latestCompletedSurvey.survey_id ?? null;
    }
  } else if (!surveys?.find((s) => s.survey_id === surveyId)) {
    navigate("/home");
  }

  // Get all respondents for every company that a user is a part of
  const { isPending: respondentsIsPending, data: respondents } = useQuery<Respondent[]>({
    queryKey: ["respondents", cogntioUserId],
    queryFn: async () => getApiData(`/user/respondents-with-groups`),
    initialData: [],
  });

  // Get all groups for every company the user is a part of 
  const { data: groups } = useQuery<Group[]>({
    queryKey: ["surveyGroups", cogntioUserId],
    queryFn: async () => getApiData(`/user/groups`),
    enabled: !!surveyId,
    initialData: [],
  });

  // get strata job titles for every company the user is a part of
  const { data: strataJobTitles } = useQuery<StrataJobTitle[]>({
    queryKey: ["strataJobTitles", cogntioUserId],
    queryFn: async () => getApiData(`/user/strata-job-titles`),
    initialData: [],
  });

  return (
    <div>
      <Report surveys={surveys} respondents={respondents} groups={groups} strataJobTitles={strataJobTitles} />
    </div>
  );
}
