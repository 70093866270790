import { Avatar } from "./Avatar";
import { Dropdown, DropdownButton, DropdownDivider, DropdownItem, DropdownLabel, DropdownMenu, DropdownShortcut } from "./Drowdown";
import { Navbar, NavbarDivider, NavbarItem, NavbarLabel, NavbarSection, NavbarSpacer } from "./NavBar";
import { Sidebar, SidebarBody, SidebarHeader, SidebarItem, SidebarLabel, SidebarSection } from "./SideBar";
import { StackedLayout } from "./StackedLayout";
import { AcademicCapIcon, ArrowRightStartOnRectangleIcon, ChevronDownIcon, Cog8ToothIcon, LightBulbIcon } from "@heroicons/react/16/solid";
import { PlusIcon, UserIcon } from "@heroicons/react/20/solid";
import { Outlet, useLocation, useNavigate } from "react-router";
import logo from "../../assets/blue.png";

import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/authContext";
import { CompanyContext } from "../../contexts/companyContext";
import useApi from "../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";
import { Company, CompanySurveySummary } from "../../types";
import Spinner from "../../components/Spinner";
import Alert from "../../components/Alert";
import { getInitialsFromName } from "../../hooks/helpers";
import { DivideIcon, HomeIcon } from "@heroicons/react/24/solid";

export default function Nav() {
  const auth = useContext(AuthContext);
  const companyContext = useContext(CompanyContext);
  const { getApiData } = useApi();
  const location = useLocation();
  const path = location.pathname.split("/")[1];

  const {
    isError: companiesIsError,
    error: companiesError,
    isPending: comaniesIsPending,
    data: companies,
  } = useQuery<Company[]>({
    queryKey: ["companies"],
    queryFn: () => getApiData(`/company/info/all`),
    initialData: [],
    enabled: !!companyContext.companyId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const { data: companySurveys } = useQuery<CompanySurveySummary[]>({
    queryKey: ["companySurveys", companyContext.companyId],
    queryFn: () => getApiData(`/company/surveys/get/latest-completed/siblings`),
    enabled: !!companyContext.companyId,
    initialData: [],
  });

  if (companiesIsError) {
    console.error(companiesError);
    return <Alert title="Error" message={"An unexpected error occurred, please try again later"} type="Error" />;
  }

  if (comaniesIsPending) {
    return <Spinner />;
  }

  const selectedCompanyId = companyContext.companyId;
  const showAllAccounts = companyContext.showAllAccounts;

  const selectedCompany = companies.find((c) => c.company_id === selectedCompanyId);

  if (!selectedCompany) {
    return <Spinner />;
  }

  const navItems = !showAllAccounts
    ? // Company nav items
      [
        { label: "Home", url: "/home", show: true },
        { label: "Results", url: "/results", show: selectedCompany.has_assessments },
        { label: "Risk", url: "/risk", show: selectedCompany.has_assessments },
        { label: "Participants", url: "/participants", show: selectedCompany.has_assessments },
        { label: "Team", url: "/team", show: companySurveys.length > 0 },
      ]
    : // All accounts nav items
      [
        { label: "Accounts", url: "/accounts", show: true },
        { label: "All Results", url: "/all", show: false },
      ];

  function TeamDropdownMenu() {
    return (
      <DropdownMenu className="min-w-80 lg:min-w-64" anchor="bottom start">
        <DropdownItem className="cursor-pointer" href="/accounts" onClick={() => companyContext.setShowAllAccounts(true)}>
          <HomeIcon />
          <DropdownLabel>All Accounts</DropdownLabel>
        </DropdownItem>

        <DropdownDivider />
        {companies.map((sub, i) => {
          return (
            <DropdownItem
              key={i}
              onClick={() => {
                companyContext.setShowAllAccounts(false);
                companyContext.setCompanyId(sub.company_id);
              }}
              href="/home"
              className="cursor-pointer"
            >
              <Avatar slot="icon" initials={getInitialsFromName(sub.company_name)} className="bg-strataBlue text-white" />
              <DropdownLabel>{sub.company_name}</DropdownLabel>
            </DropdownItem>
          );
        })}
        {/* <DropdownDivider /> */}
        {/* <DropdownItem href="#">
          <PlusIcon />
          <DropdownLabel>New Account&hellip;</DropdownLabel>
        </DropdownItem> */}
      </DropdownMenu>
    );
  }

  return (
    <StackedLayout
      navbar={
        <Navbar>
          <Avatar src={logo} className="h-8 w-8" />
          {companies.length > 1 ? (
            <Dropdown>
              <DropdownButton as={NavbarItem} className="max-lg:hidden">
                <NavbarLabel>{showAllAccounts ? "All Accounts" : selectedCompany.company_name}</NavbarLabel>
                <ChevronDownIcon />
              </DropdownButton>
              <TeamDropdownMenu />
            </Dropdown>
          ) : (
            <NavbarItem href="/home">{selectedCompany.company_name}</NavbarItem>
          )}
          <NavbarDivider className="max-lg:hidden" />
          <NavbarSection className="max-lg:hidden">
            {navItems
              .filter((i) => i.show)
              .map(({ label, url }) => (
                <NavbarItem current={url.substring(1).toLowerCase() === path} key={label} href={url}>
                  {label}
                </NavbarItem>
              ))}
          </NavbarSection>
          <NavbarSpacer />
          <NavbarSection>
            <NavbarItem href="https://learn.strataintel.com" target="_blank" aria-label="Settings">
              <AcademicCapIcon />
            </NavbarItem>
            <NavbarItem href="/settings" aria-label="Settings">
              <Cog8ToothIcon />
            </NavbarItem>
            <Dropdown>
              <DropdownButton as={NavbarItem}>
                <UserIcon />
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="bottom end">
                <DropdownItem href="/settings">
                  <UserIcon />
                  <DropdownLabel>My Account</DropdownLabel>
                </DropdownItem>
                <DropdownItem href="/contact">
                  <LightBulbIcon />
                  <DropdownLabel>Share feedback</DropdownLabel>
                </DropdownItem>
                <DropdownDivider />
                <DropdownItem href="/logout" onClick={async () => await auth.signOut()}>
                  <ArrowRightStartOnRectangleIcon />
                  <DropdownLabel>Sign out</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </NavbarSection>
        </Navbar>
      }
      sidebar={
        <Sidebar>
          <SidebarHeader>
            {companies.length > 1 ? (
              <Dropdown>
                <DropdownButton as={SidebarItem} className="lg:mb-2.5">
                  <Avatar slot="icon" initials={getInitialsFromName(selectedCompany.company_name)} className="bg-strataBlue text-white" />
                  <SidebarLabel>{showAllAccounts ? "All Accounts" : selectedCompany.company_name}</SidebarLabel>
                  <ChevronDownIcon />
                </DropdownButton>
                <TeamDropdownMenu />
              </Dropdown>
            ) : (
              <NavbarItem href="/home">
                {" "}
                <Avatar slot="icon" initials={getInitialsFromName(selectedCompany.company_name)} className="bg-strataBlue text-white" />
                {selectedCompany.company_name}
              </NavbarItem>
            )}
          </SidebarHeader>
          <SidebarBody>
            <SidebarSection>
              {navItems.map(({ label, url }) => (
                <SidebarItem key={label} href={url}>
                  {label}
                </SidebarItem>
              ))}
            </SidebarSection>
          </SidebarBody>
        </Sidebar>
      }
    >
      <Outlet />
    </StackedLayout>
  );
}
