import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool } from "amazon-cognito-identity-js";

const userPoolId = process.env.REACT_APP_USER_POOL_ID;
const clientId = process.env.REACT_APP_CLIENT_ID;

const poolData = {
  UserPoolId: `${userPoolId}`,
  ClientId: `${clientId}`,
};

const userPool: CognitoUserPool = new CognitoUserPool(poolData);

let currentUser: any = userPool.getCurrentUser();

export function getCurrentUser() {
  return currentUser;
}

function getCognitoUser(username: string) {
  const userData = {
    Username: username,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);

  return cognitoUser;
}

export async function getSession() {
  if (!currentUser) {
    currentUser = userPool.getCurrentUser();
  }

  return new Promise(function (resolve, reject) {
    currentUser.getSession(function (err: any, session: any) {
      if (err) {
        reject(err);
      } else {
        resolve(session);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function signInWithEmail(username: string, password: string) {
  return new Promise(function (resolve, reject) {
    const authenticationData = {
      Username: username,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    currentUser = getCognitoUser(username);

    currentUser.authenticateUser(authenticationDetails, {
      onSuccess: function (res: any) {
        resolve(res);
      },
      newPasswordRequired: async function (userAttributes: any, requiredAttributes: any) {
        reject({ code: "newPasswordRequired", userAttributes, session: currentUser.Session, email: username });
      },
      totpRequired: function (mfaType: string) {
        reject({ code: "totpRequired", mfaType });
      },
      onFailure: function (err: any) {
        reject(err);
      },
    });
  }).catch((err) => {
    throw err;
  });
}

export async function completeNewPasswordRegistration(email: string, newPassword: string, session: string, userAttributes: { given_name: string; family_name: string, phone_number: string | undefined }) {
  return new Promise(async function (resolve, reject) {
    currentUser = getCognitoUser(email);
    currentUser.Session = session;

    await currentUser.completeNewPasswordChallenge(newPassword, userAttributes, {
      onSuccess: function (res: any) {
        resolve(res);
      },
      onFailure: function (err: any) {
        if (err instanceof Error && err.message === "Invalid device key given.") {
          // I believe this is a bug in the amazon-cognito-identity-js library: https://github.com/aws-amplify/amplify-js/issues/2087
          // if we get this type of error, just ignore the error and resolve the promise
          resolve(err);
        }
        reject(err);
      },
    });
  }).catch((err) => {
    throw err;
  });
}

export async function signOut() {
  if (currentUser) {
    return new Promise(function (resolve, reject) {
      currentUser.signOut(() => {
        resolve("signed out");
        localStorage.clear();
      });
    });
  }
}

export async function getAttributes() {
  return new Promise(function (resolve, reject) {
    currentUser.getUserAttributes(function (err: any, attributes: any) {
      if (err) {
        reject(err);
      } else {
        resolve(attributes);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function getUserData() {
  return new Promise(function (resolve, reject) {
    currentUser.getUserData(function (err: any, userData: any) {
      if (err) {
        reject(err);
      } else {
        resolve(userData);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function listRememberedDevices() {
  return new Promise(function (resolve, reject) {
    const limit = 10;
    const paginationToken = null;
    currentUser.listDevices(limit, paginationToken, {
      onSuccess: function (result: any) {
        resolve(result.Devices);
      },
      onFailure: function (err: Error) {
        reject(err);
      },
    });
  });
}

export async function setAttribute(attribute: CognitoUserAttribute) {
  return new Promise(function (resolve, reject) {
    const attributeList = [];
    const res = new CognitoUserAttribute(attribute);
    attributeList.push(res);

    currentUser.updateAttributes(attributeList, (err: any, res: any) => {
      if (err) {
        reject(err);
      } else {
        resolve(res);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function sendCode(username: string) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username);

    if (!cognitoUser) {
      reject(`could not find ${username}`);
      return;
    }

    cognitoUser.forgotPassword({
      onSuccess: function (res) {
        resolve(res);
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  }).catch((err) => {
    throw err;
  });
}

export async function forgotPassword(username: string, code: string, password: string) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username);

    if (!cognitoUser) {
      reject(`could not find ${username}`);
      return;
    }

    cognitoUser.confirmPassword(code, password, {
      onSuccess: function () {
        resolve("password updated");
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  });
}

export async function changePassword(oldPassword: string, newPassword: string) {
  return new Promise(function (resolve, reject) {
    currentUser.changePassword(oldPassword, newPassword, function (err: any, res: any) {
      if (err) {
        reject(err);
      } else {
        resolve(res);
      }
    });
  });
}

export async function verifyOTP(otp: string, rememberDevice?: boolean) {
  return new Promise(function (resolve, reject) {
    currentUser.sendMFACode(
      otp,
      {
        onSuccess: () => {
          if (rememberDevice) {
            addRememberedDevice();
          }
          resolve(true);
        },
        onFailure: (err: Error) => {
          reject(err);
        },
      },
      "SOFTWARE_TOKEN_MFA"
    );
  });
}

export async function setupMFA() {
  return new Promise(function (resolve, reject) {
    currentUser.associateSoftwareToken({
      associateSecretCode: function (secretCode: any) {
        resolve(secretCode);
      },
      onFailure: function (err: Error) {
        console.error("Failed to associate software token:", err);
        reject(err);
      },
    });
  });
}

export async function confirmMFA(otp: string) {
  return new Promise(function (resolve, reject) {
    currentUser.verifySoftwareToken(otp, "MyMFADeviceName", {
      onSuccess: function (result: any) {
        const totpMfaSettings = {
          PreferredMfa: true,
          Enabled: true,
        };
        currentUser.setUserMfaPreference(null, totpMfaSettings, function (err: Error, result: any) {
          if (err) {
            reject(err);
          }
          resolve(true);
        });
      },
      onFailure: function (err: Error) {
        reject(err);
      },
    });
  });
}

export async function forgetDevice(deviceKey: string) {
  return new Promise(function (resolve, reject) {
    currentUser.forgetSpecificDevice(deviceKey, {
      onSuccess: (result: any) => resolve(result),
      onFailure: (err: Error) => reject(err),
    });
  });
}

function addRememberedDevice() {
  currentUser.getDevice({
    onSuccess: function (result: any) {
      console.log("Device remembered successfully", result);
    },
    onFailure: function (err: Error) {
      console.error("Failed to remember device:", err);
    },
  });

  currentUser.setDeviceStatusRemembered({
    onSuccess: function (result: any) {
      console.log("Device status set to remembered successfully");
    },
    onFailure: function (err: Error) {
      console.error("Failed to set device status to remembered:", err);
    },
  });
}
