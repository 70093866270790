import Papa from "papaparse";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import UsersTable from "./UsersTable";
import useApi from "../../hooks/useApi";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { CompanyContext } from "../../contexts/companyContext";
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from "@headlessui/react";
import { ArrowUpOnSquareIcon, ExclamationTriangleIcon, PhotoIcon, XMarkIcon } from "@heroicons/react/24/outline";
import IconButton from "../../components/IconButton";
import Alert from "../../components/Alert";
import { ParticipantUpload, ParticipantWithGroups } from "../../types";
import { UserPlusIcon } from "@heroicons/react/16/solid";
import UserUpload from "./UserUpload";

export default function UserUploadModal(props: {
  showUpload: boolean;
  setShowUpload: (value: boolean) => void;
  setSuccessNotification?: (text: string) => void;
}) {
  const companyContext = useContext(CompanyContext);
  const { getApiData } = useApi();

  const { data: participants } = useQuery<ParticipantWithGroups[]>({
    queryKey: ["participants", companyContext.companyId],
    queryFn: async () => getApiData(`/company/users/with-groups`),
    initialData: [],
  });

  return (
    <Transition show={props.showUpload}>
      <Dialog className="relative z-10" onClose={props.setShowUpload}>
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl sm:p-6">
                <div className="">
                  <div className="px-4 py-5 sm:px-6">
                    <div className="flex justify-between">
                      <h3 className="text-xl leading-6 font-medium text-gray-800 mb-5">Bulk Import Particpants</h3>
                      <IconButton icon={XMarkIcon} className="h-7 text-gray-500" onClick={() => props.setShowUpload(false)} />
                    </div>

                    <p className="mt-1 text-sm text-gray-500 mb-4">
                      To update participants, first upload a CSV file containing employee information. You can download the template{" "}
                      <Link to="/Strata_Intel_User_Upload_Template.csv" className="text-strataBlue underline" target="_blank" download>
                        here
                      </Link>
                    </p>
                    {participants.length > 0 && (
                      <Alert
                        title="This will replace your current list"
                        message="Please ensure all team members you wish to
                      participate are included."
                        icon={ExclamationTriangleIcon}
                      />
                    )}
                    <div className="mt-4">
                      <UserUpload onUpload={() => props.setShowUpload(false)} />
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
