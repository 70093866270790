import { useContext } from "react";
import { CompanyContext } from "../../contexts/companyContext";
import useApi from "../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";
import { Company, CompanySurvey, CompanySurveySummary } from "../../types";
import CompanyOverview from "../Home/CompanyOverview";

export default function Overview() {
  const { getApiData } = useApi();
  const companyContext = useContext(CompanyContext);
  const companyId = companyContext.companyId;

  const {
    data: companySurveys,
  } = useQuery<CompanySurveySummary[]>({
    queryKey: ["companySurveys"],
    queryFn: () => getApiData(`/company/info/all-with-latest-completed-survey`),
    initialData: [],
    enabled: !!companyContext.companyId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const {
    data: companies,
  } = useQuery<Company[]>({
    queryKey: ["companies"],
    queryFn: () => getApiData(`/company/info/all`),
    initialData: [],
    enabled: !!companyContext.companyId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  return (
    <>
      {companySurveys.length > 0 && (
        <div>
          <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
            {companies.map((company, index) => (
              <div key={index}>
                <CompanyOverview company={company} />
              </div>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}
