import { useState } from "react";
import { Dialog, DialogPanel, Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import StressCurve from "./stressCurve.png";

export default function ReportHelp(props: { openHelp: boolean; setOpenHelp: (e: boolean) => void }) {
  const { openHelp, setOpenHelp } = props;

  return (
    <Dialog open={openHelp} onClose={setOpenHelp} className="relative z-10">
      <div className="fixed inset-0" />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <DialogPanel transition className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700">
              <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                <div className="px-4 py-6 sm:px-6">
                  <div className="flex items-start justify-between">
                    <h2 id="slide-over-heading" className="text-base font-semibold leading-6 text-gray-900">
                      Help
                    </h2>
                    <div className="ml-3 flex h-7 items-center">
                      <button type="button" onClick={() => setOpenHelp(false)} className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500">
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                      </button>
                    </div>
                  </div>
                </div>
                {/* Main */}
                <div>
                  <div className="pb-1 sm:pb-4">
                    <div>
                      <div className="relative h-40 sm:h-56">
                        <img alt="" src={StressCurve} className="absolute h-full w-full object-cover" />
                      </div>
                      <div className="mt-6 px-4 sm:mt-5 sm:flex sm:items-end sm:px-6">
                        <div className="sm:flex-1">
                          <div className="flex flex-col">
                            <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">Stress Performance Curve</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 pb-5 pt-5 sm:px-0 sm:pt-0">
                    <dl className="space-y-8 px-4 sm:space-y- sm:px-6">
                      <div>
                        <dd className="text-md text-gray-900 sm:col-span-2">
                          <p>
                            Strata Intel measures how your team falls on the performance-stress curve—whether they are in the green range, where stress is balanced and performance is high, or slipping
                            into yellow or red, where rising stress impacts their ability to perform.
                          </p>
                        </dd>
                      </div>
                      <div>
                        <dt className="text-md font-bold text-gray-500 sm:w-40 sm:flex-shrink-0">Disengaged</dt>
                        <dd className="mt-1 text-md text-gray-900 sm:col-span-2">
                          The disengaged range includes individuals who complete the assessment but may not be fully honest in their responses, often reporting unrealistically low stress. This can
                          indicate frustration, feeling threatened, or reluctance to participate.
                        </dd>
                      </div>
                      <div>
                        <dt className="text-md font-bold text-green-500 sm:w-40 sm:flex-shrink-0">Optimized</dt>
                        <dd className="mt-1 text-md text-gray-900 sm:col-span-2">
                          The green range represents your optimized individuals experiencing regular, sustainable stress that fuels high engagement and performance. This is the optimal range, where
                          stress is balanced, keeping them engaged without overwhelming them.
                        </dd>
                      </div>
                      <div>
                        <dt className="text-md font-bold text-yellow-500 sm:w-40 sm:flex-shrink-0">Elevated</dt>
                        <dd className="mt-1 text-md text-gray-900 sm:col-span-2">
                          The yellow range signals rising stress that is unsustainable over time. Employees may experience issues like life imbalance due to work or personal circumstance, frustration, sleep dysfuction, and a sense of being overwhelmed. While brief periods in the yellow range are not uncommon, prolonged stress in this range leads to burnout, employee resentment, and higher turnover risk.
                        </dd>
                      </div>
                      <div>
                        <dt className="text-md font-bold text-red-500 sm:w-40 sm:flex-shrink-0">At-Risk</dt>
                        <dd className="mt-1 text-md text-gray-900 sm:col-span-2">
                          The red range indicates extremely high stress which correlates with life impairment and dysfunciton. Individuals in this range often require immediate support and attention.
                          It is not uncommon for these inviduals to be experiencing active or passive suicidal thoughts. This is a critical warning, one employee in the red range should be addressed
                          with urgency and care.
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
