import { createContext, useState, ReactNode, FC, useContext, useEffect } from "react";
import { AuthContext } from "./authContext";
import { useNavigate } from "react-router";

interface CompanyContextType {
  companyId: number | null;
  setCompanyId: (id: number | null) => void;
  showAllAccounts: boolean;
  setShowAllAccounts: (value: boolean) => void;
}

const CompanyContext = createContext<CompanyContextType>({
  companyId: null,
  setCompanyId: () => console.log("setCompanyId"),
  showAllAccounts: false,
  setShowAllAccounts: () => console.log("setShowAllAccounts"),
});

export const useCompanyContext = () => {
  return useContext(CompanyContext);
};

const CompanyProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const localCompanyId = localStorage.getItem("companyId");
  const localShowAllAccounts = localStorage.getItem("showAllAccounts");
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const [companyId, setCompanyId] = useState<number | null>(localCompanyId ? parseInt(localCompanyId) : null);
  const [showAllAccounts, setShowAllAccounts] = useState<boolean>(localShowAllAccounts === "true");

  function setCompanyIdWithLocal(companyId: number | null) {
    setCompanyId(companyId);
    if (companyId) {
      localStorage.setItem("companyId", companyId.toString());
      setShowAllAccountsWithLocal(false);
    }
  }

  function setShowAllAccountsWithLocal(value: boolean) {
    setShowAllAccounts(value);
    localStorage.setItem("showAllAccounts", value ? "true" : "false");
  }

  useEffect(() => {
    async function getUserCompanyId() {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/roles`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: auth.sessionInfo.idToken ?? "",
        },
      });
      if (response.status === 200) {
        const body = await response.json();
        if(!body || body.length <= 0) {
          setCompanyIdWithLocal(null); // user is not assigned to any companies
          return;
        }
        if (body.length > 1) {
          setCompanyIdWithLocal(body[0].company_id);
          setShowAllAccountsWithLocal(true);
          navigate("/accounts")
        } else {
          setCompanyIdWithLocal(body[0].company_id);
        }
      }
    }

    if (!companyId) {
      getUserCompanyId();
    }
  }, [companyId, auth]);

  return (
    <CompanyContext.Provider value={{ companyId: companyId, setCompanyId: setCompanyIdWithLocal, showAllAccounts, setShowAllAccounts: setShowAllAccountsWithLocal }}>
      {children}
    </CompanyContext.Provider>
  );
};

export { CompanyContext, CompanyProvider };
