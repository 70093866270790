import Button from "../../components/Button";
import { useContext } from "react";
import Spinner from "../../components/Spinner";
import Logo from "../../assets/blue.png";
import { Subscription } from "../../types";
import { capitalizeFirstLetter } from "../../hooks/helpers";
import Table from "../../components/Table";
import Payment from "../../components/Payment";
import { useQuery } from "@tanstack/react-query";
import { CompanyContext } from "../../contexts/companyContext";
import useApi from "../../hooks/useApi";

export default function ManagePayment() {
  const companyContext = useContext(CompanyContext);
  const { getApiData } = useApi(); 

  const { isPending: paymentIsPending, data: paymentInfo } = useQuery<Subscription>({
    queryKey: ["payment", companyContext.companyId],
    queryFn: () => getApiData(`/company/payment/info`),
    retry: false
  });

  const { data: paymentMethods } = useQuery<any[]>({
    queryKey: ["paymentMethods", companyContext.companyId],
    queryFn: () => getApiData(`/company/payment/list-payment-methods`),
    initialData: [],
    retry: false
  });

  const { data: invoices } = useQuery<any[]>({
    queryKey: ["invoices", companyContext.companyId],
    queryFn: () => getApiData(`/company/payment/invoices`),
    initialData: [],
    retry: false
  });

  if (paymentIsPending) {
    return <Spinner />;
  }
  if (
    !paymentInfo?.stripe_subscription_id ||
    paymentInfo?.stripe_subscription_status === "incomplete" ||
    paymentInfo?.stripe_subscription_status === "incomplete_expired"
  ) {
    return (
      <div className="sm:flex">
        <div className="sm:w-1/3 sm:mr-5">
          <div className="bg-white rounded-lg shadow-md p-5 flex flex-col items-start w-full">
            <div className="flex items-center space-x-2">
              <div className="flex-shrink-0">
                <div className="relative">
                  <img className="h-16 w-16 rounded-full" src={Logo} alt="" />
                  <span
                    className="absolute inset-0 rounded-full shadow-inner"
                    aria-hidden="true"
                  />
                </div>
              </div>
              <div className="text-xl font-semibold mt-2 text-gray-500">
                Strata Intel - Monthly Subscription
              </div>
            </div>
            <div className="ml-2 mt-5">
              {invoices.length > 0 && (
                <div className="text-gray-500 text-sm">
                  Amount Due:{" "}
                  <span className="text-gray-700 font-semibold">
                    ${invoices[0].amount_due / 100}.00
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="sm:w-2/3">
          <div className="bg-white rounded-lg shadow-md p-5">
            <Payment reloadOnSuccess={true}/>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="">
        <div>
        <div className="px-4 sm:px-0 border-b mb-4">
          <h3 className="text-base font-semibold leading-7 text-gray-900">Subscription Information</h3>
          <p className="mt-1 mb-2 max-w-2xl text-sm leading-6 text-gray-500">Details of Subscription</p>
        </div>
          <div className="md:flex md:items-center md:justify-between mt-3 bg-white p-5 rounded-lg shadow">
            <div className="flex space-x-5 items-center">
              <div className="flex-shrink-0">
                <div className="relative">
                  <img className="h-16 w-16 rounded-full" src={Logo} alt="" />
                  <span
                    className="absolute inset-0 rounded-full shadow-inner"
                    aria-hidden="true"
                  />
                </div>
              </div>
              <div className="pt-1.5 flex align-middle h-full">
                <h2 className="text-2xl font-bold text-gray-900">
                  Strata Intel - Monthly
                </h2>
              </div>
            </div>
            <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
              <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-3 py-1 font-medium text-green-700 ring-1 ring-inset ring-green-600/20 mr-3">
                {capitalizeFirstLetter(paymentInfo.stripe_subscription_status)}
              </span>
            </div>
          </div>
        </div>
        <div className="py-5">
          <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="mt-2">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Payment Methods
              </h3>
            </div>
            <div className="mt-2 flex-shrink-0">
              <Button
                text="Add New Payment Method"
                disabled={true}
                hoverMessage="To add a new payment method, please contact a Strata Intel administrator"
              />
            </div>
          </div>
          <ul
            className="grid grid-cols-1 gap-6 sm:grid-cols-2 mt-2"
          >
            {paymentMethods.map((paymentMethod: any) => (
              <li
                key={paymentMethod.id}
                className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
              >
                {paymentMethod.card && (
                  <div className="flex w-full items-center justify-between space-x-10 p-8">
                    <div className="flex-1 truncate">
                      <div className="flex items-center space-x-3">
                        <h3 className="truncate text-sm font-medium text-gray-900">
                          {capitalizeFirstLetter(paymentMethod.card.brand)}:{" "}
                          {paymentMethod.card.last4}
                        </h3>
                        <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                          {capitalizeFirstLetter(paymentMethod.card.funding)}
                        </span>
                      </div>
                      <p className="mt-1 truncate text-sm text-gray-500">
                        {paymentMethod.card.exp_month}/
                        {paymentMethod.card.exp_year}
                      </p>
                    </div>
                    <div className="mx-auto">
                      <Button
                        text="Edit"
                        disabled={true}
                        hoverMessage="To update credit card information, please contact a Strata Intel administrator"
                      />
                    </div>
                  </div>
                )}
                {paymentMethod.us_bank_account && (
                  <div className="flex w-full items-center justify-between space-x-10 p-8">
                    <div className="flex-1 truncate">
                      <div className="flex items-center space-x-3">
                        <h3 className="truncate text-sm font-medium text-gray-900">
                          {capitalizeFirstLetter(paymentMethod.us_bank_account.bank_name)}:{" "}
                          
                        </h3>
                        <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-strataBlue ring-1 ring-inset ring-blue-600/20">
                          {capitalizeFirstLetter(paymentMethod.us_bank_account.account_type)}
                        </span>
                      </div>
                      <p className="mt-1 truncate text-sm text-gray-500">
                      {paymentMethod.us_bank_account.last4}
                      </p>
                    </div>
                    <div className="mx-auto">
                      <Button
                        text="Edit"
                        disabled={true}
                        hoverMessage="To update bank account information, please contact a Strata Intel administrator"
                      />
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ul>
          <div className="py-5 mt-5">
            <div className="items-center justify-between">
              <div className="mt-2 mb-5">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  Invoices
                </h3>
              </div>
              <Table
                items={invoices}
                structure={[
                  {
                    header: "Date",
                    column: "period_start",
                    customFormat: (date) =>
                      new Date((date as number) * 1000).toDateString(),
                  },
                  { header: "Total", column: "total", format: "cents" },
                  {
                    header: "Status",
                    column: "status",
                    customFormat: (text) =>
                      capitalizeFirstLetter(text as string),
                  },
                  {
                    header: "",
                    column: "hosted_invoice_url",
                    button: {
                      onClick: (value: string) => window.open(value, "_blank"),
                      text: "View Details",
                    },
                  },
                ]}
                limitPerPage={5}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
