import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import useApi from "../../hooks/useApi";
import { CompanyContext } from "../../contexts/companyContext";
import { Survey, Respondent, Group, StrataJobTitle } from "../../types";
import { getLatestCompletedSurvey } from "../../hooks/helpers";
import Report from "./Report";

export default function Results() {
  const companyContext = useContext(CompanyContext);
  const { getApiData } = useApi();
  const location = useLocation();
  const navigate = useNavigate();
  const surveyIdPath = parseInt(location.pathname.replace("/assessments/", ""));

  let surveyId: number | null = null;
  if (!isNaN(surveyIdPath)) {
    surveyId = surveyIdPath;
  }

  const { data: surveys } = useQuery<Survey[]>({
    queryKey: ["surveys", companyContext.companyId],
    queryFn: async () => getApiData(`/company/surveys`),
    initialData: [],
  });

  if (!surveyId) {
    const latestCompletedSurvey = getLatestCompletedSurvey(surveys);

    if (latestCompletedSurvey) {
      surveyId = latestCompletedSurvey.survey_id ?? null;
    }
  } else if (!surveys?.find((s) => s.survey_id === surveyId)) {
    navigate("/home");
  }

  // Get all respondents for a company no matter the survey
  const { isPending: respondentsIsPending, data: respondents } = useQuery<Respondent[]>({
    queryKey: ["respondents", companyContext.companyId],
    queryFn: async () => getApiData(`/company/surveys/all/respondents-with-groups`),
    initialData: [],
  });

  // Get all groups with the min number of respondents for the assessment
  const { data: groups } = useQuery<Group[]>({
    queryKey: ["surveyGroups", companyContext.companyId],
    queryFn: async () => getApiData(`/company/groups/survey/${surveyId}`),
    enabled: !!surveyId,
    initialData: [],
  });

  // get strata job titles
  const { data: strataJobTitles } = useQuery<StrataJobTitle[]>({
    queryKey: ["strataJobTitles", companyContext.companyId],
    queryFn: async () => getApiData(`/company/info/strata-job-titles`),
    initialData: [],
  });

  return (
    <div>
      <Report surveys={surveys} respondents={respondents} groups={groups} strataJobTitles={strataJobTitles} />
    </div>
  );
}
